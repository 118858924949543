import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";

import {
  GroupedProductRows,
  ProductRow,
} from "../../../../../../data/Product/models";
import ProductsCategory from "./ProductsCategory";
import ProductDialogHeader from "./ProductDialogHeader";
import Transition from "../../../../../common/animation/DialogTransition";
import SectionLoading from "../../../../../common/loading/SectionLoading";
import { ProductDictionary } from "../ProductsSection";
import ErrorMessage from "../../../../../common/error/ErrorMessage";
import { IncidentProductRowNew } from "../../../../../../data/Incident/types/standardEquipment";
import { useQuery } from "@tanstack/react-query";
import { getProducts } from "../../../../../../data/Product/queries";
import { QueryError } from "../../../../../../types";
import QueryErrorMessageInner from "../../../../../common/error/QueryErrorMessageInner";

type Props = {
  open: boolean;
  productType: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productDictionary: ProductDictionary;
  handleAddProduct: (product: IncidentProductRowNew) => void;
};

const ProductDialog = ({
  productType,
  open,
  setOpen,
  productDictionary,
  handleAddProduct,
}: Props) => {
  const defaultCategory = "Toutes les catégories";
  const [category, setCategory] = useState<string>(defaultCategory);
  const [searchText, setSearchText] = useState<string>("");
  const theme = useTheme();

  const productsQuery = useQuery<ProductRow[], QueryError>({
    queryKey: ["Products", productType],
    queryFn: () => getProducts(productType),
    staleTime: Infinity,
    gcTime: 2000,
  });

  const groupedProducts = useMemo(
    () =>
      productsQuery.data
        ? productsQuery.data.reduce((acc: GroupedProductRows, item) => {
            if (!acc[item._vdl_categorie_value_FormattedValue]) {
              acc[item._vdl_categorie_value_FormattedValue] = [];
            }
            acc[item._vdl_categorie_value_FormattedValue].push(item);
            return acc;
          }, {})
        : {},
    [productsQuery.data]
  );


  const filteredCategoryItems = (
    category: string,
    groupedProducts: GroupedProductRows
  ): ProductRow[] => {
    if (searchText === "") return groupedProducts[category];

    return groupedProducts[category].filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const renderSwtich = () => {
    if (productsQuery.isLoading) return <SectionLoading />;

    if (!productsQuery.data || productsQuery.error)
      return <QueryErrorMessageInner error={productsQuery.error} />;

    if (productsQuery.data.length === 0)
      return (
        <ErrorMessage
          message={
            "Il est possible que vous n'ayez accès à aucune catégorie de produits. Veuillez contacter la ville pour obtenir de l'aide."
          }
        />
      );

    if (category === defaultCategory) {
      return Object.keys(groupedProducts).map((category) => (
        <ProductsCategory
          key={category}
          category={category}
          products={filteredCategoryItems(category, groupedProducts)}
          productDictionary={productDictionary}
          handleAddProduct={handleAddProduct}
        />
      ));
    } else {
      return (
        <ProductsCategory
          category={category}
          products={filteredCategoryItems(category, groupedProducts)}
          productDictionary={productDictionary}
          handleAddProduct={handleAddProduct}
        />
      );
    }
  };
  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition} keepMounted>
      <Box display="flex" flexDirection="column" height="100%">
        <ProductDialogHeader
          defaultCategory={defaultCategory}
          category={category}
          categories={Object.keys(groupedProducts)}
          setCategory={setCategory}
          setOpen={setOpen}
          setSearchText={setSearchText}
          searchText={searchText}
        />
        <Box
          height="100%"
          sx={{ overflowY: "auto" }}
          bgcolor="bg.light"
          component="div"
        >
          <Box padding={"16px"}>{renderSwtich()}</Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={"secondary.main"}
          paddingY="16px"
        ></Box>
      </Box>
    </Dialog>
  );
};

export default ProductDialog;
