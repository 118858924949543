import { Box, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandableFolder, {
  fileStyles,
} from "../components/pageSpecific/Documents/ExpandableFolder";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadbleFile from "../components/pageSpecific/Documents/DownloadableFile";
import { DocumentRow } from "../data/Document/models";
import DocumentSkeleton from "../components/pageSpecific/Documents/DocumentSkeleton";
import ErrorMessage from "../components/common/error/ErrorMessage";
import { useQuery } from "@tanstack/react-query";
import { QueryError } from "../types";
import { getFiles } from "../data/Document/queries";
import QueryErrorMessageInner from "../components/common/error/QueryErrorMessageInner";
import SearchField from "../components/pageSpecific/Demands/SearchField";

function filterMatchingObjects(
  obj: DocumentRow | DocumentRow[],
  filterValue: string
): DocumentRow[] {
  if (Array.isArray(obj)) {
    const filteredArray: DocumentRow[] = obj.flatMap((child) =>
      filterMatchingObjects(child, filterValue)
    );
    return filteredArray;
  } else {
    if (obj.type === "folder") {
      const filteredObj: DocumentRow = {
        name: obj.name,
        type: obj.type,
        id: obj.id,
        files: [],
      };
      if (obj.name.toLowerCase().includes(filterValue.toLowerCase())) {
        filteredObj.files = obj.files;
        return [filteredObj];
      } else {
        const filteredChildren: DocumentRow[] = obj.files.flatMap((file) =>
          filterMatchingObjects(file, filterValue)
        );
        if (filteredChildren.length > 0) {
          filteredObj.files = filteredChildren;
          return [filteredObj];
        }
      }
    } else if (obj.name.toLowerCase().includes(filterValue.toLowerCase())) {
      return [{ name: obj.name, type: obj.type, id: obj.id }];
    }
    return [];
  }
}

const Documents = () => {
  const [searchValue, setSearchValue] = useState("");

  const { data, error, isLoading, isFetching } = useQuery<
    DocumentRow[],
    QueryError
  >({
    queryKey: ["Demands"],
    queryFn: () => getFiles(),
  });

  function handleFilter() {
    if (!data) return [];
    if (searchValue.trim() === "") {
      return data;
    }
    return filterMatchingObjects(data, searchValue);
  }

  if (isLoading) return <DocumentsPagesLoading />;

  if (!data || error) return <QueryErrorMessageInner error={error} />;

  return (
    <PageTitleLayout title="Documents">
      <BoxResponsivePadding display="flex" flexDirection="column" gap="8px">
        <SearchField
          setDebouncedSearchValue={setSearchValue}
          size={"small"}
        />

        {handleFilter().map((dossier, index) => {
          if (dossier.type === "folder") {
            return (
              <ExpandableFolder
                folder={dossier}
                level={0}
                key={dossier.id + index}
              />
            );
          }
          if (dossier.type === "file") {
            return (
              <Box key={dossier.id + index} sx={{ ...fileStyles.button }}>
                <DownloadbleFile file={dossier} />
              </Box>
            );
          }
        })}
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default Documents;

const DocumentsPagesLoading = () => {
  return (
    <PageTitleLayout isLoading title="Documents">
      <BoxResponsivePadding display="flex" flexDirection="column">
        <DocumentSkeleton />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
