import { ServiceRow } from "../Service/models";
import { ErrorType, OperationType, QueryError } from "../../types";
import fetchMsal from "../fetchMsal";
import { ProductRow } from "./models";

export const getProducts = async (typeId: string): Promise<
ProductRow[]
> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_products/${typeId}`;
    const result = await fetchMsal("GET", endpoint);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Produits",
      OperationType.RETRIEVEMULTIPLE
    );
  }
};

