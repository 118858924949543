import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useUser from "../hooks/useUserData";
import ProductDialog from "../components/pageSpecific/EditDemand/StandardEquipment/Products/ProductsDialog/ProductDialog";
import fetchWithMsal from "../data/fetchWithMsal";
import CustomChip from "../components/common/misc/CustomChip";
import { getProducts } from "../data/Product/queries";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { ErrorType, FormFields, MultilineInput, QueryError, TabContent, TextInput } from "../types";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import BasicTabs from "../components/layouts/AppTabs/AppTabs";
import { useSnackbar } from "../hooks/useSnackbar";
import fetchMsal from "../data/fetchMsal";

const Test = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { userData } = useUser();
  const theme = useTheme();
  const {showSnackbar, showErrorSnackbar} = useSnackbar();

  useEffect(() => {
    //const querry = async () => {
    //const { data, error } = await getProducts();
    //};
    //querry();
  }, []);

  const tabData: TabContent[] = [
    {
      label: "Informations générales",
      component: null,
    },
    {
      label: "Voie publique",
      component: null,
    },
    {
      label: "Sécurité prévention",
      component: null,
    },
    {
      label: "Permis",
      component: null,
    },
    {
      label: "Soutient événement",
      component: null,
    },
    {
      label: "Soutien Eco-Responsable",
      component: null,
    },
    {
      label: "Affichage sur le calendrier",
      component: null,
    },
    {
      label: "Affichage sur panneaux électronique",
      component: null,
    },
    {
      label: "Affichage sur Prisme",
      component: null,
    },
    {
      label: "Affichage télévision",
      component: null,
    },
    {
      label: "Demande information",
      component: null,
    },
  ];
  const [open, setOpen] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);

  const shouldDisplayButton = () => {
    if (contentRef.current) {
      const contentElement = contentRef.current;

      return contentElement.scrollWidth > contentElement.clientWidth;
    }
  };
  if (0 > 1)
    return (
      <PageTitleLayout title={"Test"}>
        <BasicTabs tabs={tabData} scrollable />
      </PageTitleLayout>
    );

  return (
    <Box>
      <Box>
        <Typography variant="h6">Item Title</Typography>
      </Box>
      <Box sx={{ position: 'relative', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <Typography variant="body1" sx={{ maxWidth: '100vw', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          Need "Read More" if it is not don't Need "Read More" if it is not
          don'tvNeed "Read More" if it is not don'tNeed "Read More" if it is not
          don'tNeed "Read More" if it is not don'tNeed "Read More" if it is not
          don'tNeed "Read More" if it is not don'tNeed "Read More" if it is not
          don't
        </Typography>
        <Button
          className="text-size"
          onClick={() => setOpen(!open)}
          sx={{
            display: open ? "none" : "block",
            textAlign: "right",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Read more...
        </Button>

        <Button
          className="text-size"
          onClick={() => showErrorSnackbar("Impossible de modifier la demande.", "error", new QueryError(ErrorType.NOT_FOUND, "mess", "Demande"))}
          sx={{
            display: open ? "none" : "block",
            textAlign: "right",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Read more...
        </Button>
      </Box>
    </Box>
  );
};

export default Test;

/*
const SecuredImage = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetchMsal("GET", "http://localhost:7071/api/get_products");
        if (response && response.ok) {
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          setImageSrc(objectUrl);
        } else {
          console.error("Failed to fetch image");
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc); // Cleanup
      }
    };
  }, []);

  if (!imageSrc) return <p>Loading...</p>;

  return <img src={imageSrc} alt="Secured Content" />;
};
*/