import { Box, Typography } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import CustomButton from "../../../../common/buttons/CustomButton";
import ProductDialog from "./ProductsDialog/ProductDialog";
import ProductCard from "./ProductCard";
import { ProductRow } from "../../../../../data/Product/models";
import { getProducts } from "../../../../../data/Product/queries";
import {
  IncidentProductRowEdit,
  IncidentProductRowNew,
} from "../../../../../data/Incident/types/standardEquipment";
import { usePrefetchQuery, useQuery } from "@tanstack/react-query";
import { QueryError } from "../../../../../types";

export type ProductDictionary = {
  [key: string]: number;
};

type Props = {
  demandProducts: IncidentProductRowEdit[];
  productTypes: string;
  setDemandProducts: React.Dispatch<
    React.SetStateAction<IncidentProductRowEdit[]>
  >;
};

const ProductsSection = memo(({
  demandProducts,
  productTypes,
  setDemandProducts,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const productsQuery = useQuery<ProductRow[], QueryError>({
    queryKey: ["Products", productTypes],
    queryFn: () => getProducts(productTypes),
    staleTime: Infinity,
    gcTime: 2000,
  });

  const filteredProduts = useMemo(
    () => demandProducts.filter((product) => product.vdl_quantite > 0),
    [demandProducts]
  );

  const productDictionary = useMemo(
    () =>
      demandProducts.reduce((dictionary: ProductDictionary, demandProduct) => {
        if (demandProduct.vdl_quantite > 0) {
          dictionary[demandProduct.vdl_Produit.productid] =
            demandProduct.vdl_quantite;
        }
        return dictionary;
      }, {}),
    [demandProducts]
  );

  const handleAddProduct = (product: IncidentProductRowEdit) => {
    console.log(product)
    const demandProduct = demandProducts.find(
      (p) => p.vdl_Produit.productid === product.vdl_Produit.productid
    );

    if (demandProduct) {
      setDemandProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.vdl_Produit.productid === product.vdl_Produit.productid
            ? {
                ...p,
                vdl_quantite: product.vdl_quantite,
                error: undefined,
                delete: undefined,
              }
            : p
        )
      );
    } else {
      setDemandProducts([...demandProducts, product]);
    }
  };

  const handleDeleteProduct = (product: IncidentProductRowNew) => {
    if ("vdl_equipementdelademandeid" in product) {
      setDemandProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.vdl_Produit.productid === product.vdl_Produit.productid
            ? { ...p, vdl_quantite: 0, delete: true }
            : p
        )
      );
    } else {
      setDemandProducts((prevProducts) =>
        prevProducts.filter(
          (p) => p.vdl_Produit.productid !== product.vdl_Produit.productid
        )
      );
    }
  };
  const handleChangeProductQty = (productid: string, newQty: number) => {
    const catalogProduct = productsQuery.data
      ? productsQuery.data.find((p) => p.productid === productid)
      : undefined;

    if (!catalogProduct) {
      setDemandProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.vdl_Produit.productid === productid
            ? { ...product, error: "Impossible de récupérer l'inventaire" }
            : product
        )
      );
      return;
    }

    if (newQty === 0) {
      setDemandProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.vdl_Produit.productid === productid
            ? { ...product, error: "Quantité invalide" }
            : product
        )
      );
      return;
    }

    if (catalogProduct.quantityonhand && catalogProduct.quantityonhand < newQty) {
      setDemandProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.vdl_Produit.productid === productid
            ? { ...product, error: "Quantité insufisante" }
            : product
        )
      );
      return;
    }
    setDemandProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.vdl_Produit.productid === productid
          ? { ...product, vdl_quantite: newQty, error: undefined }
          : product
      )
    );
  };

  return (
    <>
      <ProductDialog
        productType={productTypes}
        open={dialogOpen}
        setOpen={setDialogOpen}
        productDictionary={productDictionary}
        handleAddProduct={handleAddProduct}
      />
      <Box>
        <Box display="flex" justifyContent="space-between" paddingBottom="16px">
          <Typography variant="h5" color="primary" paddingBottom="4px">
            Matériel
          </Typography>
          <CustomButton primary onClick={() => setDialogOpen(true)}>
            Ajouter
          </CustomButton>
        </Box>
        <Box display="grid" gap="16px">
          {filteredProduts.length > 0 ? (
            filteredProduts.map((product) => (
              <ProductCard
                key={product.vdl_Produit.productid}
                product={product}
                handleDeleteProduct={handleDeleteProduct}
                handleChangeProductQty={handleChangeProductQty}
              />
            ))
          ) : (
            <Box bgcolor="background.default" padding="16px">
              <Typography>Il n'y a aucun produit dans la demande</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
});

export default ProductsSection;
