import { TextField, InputAdornment, TextFieldPropsSizeOverrides, TextFieldProps } from "@mui/material";
import { OverridableStringUnion } from '@mui/types';
import { ClearIcon } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  setDebouncedSearchValue: React.Dispatch<React.SetStateAction<string>>;
  size?: TextFieldProps["size"];
  bgColor?: string | undefined
};
const SearchField = ({ setDebouncedSearchValue, size, bgColor }: Props) => {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  const handleClear = () => {
    setSearchValue("");
    setDebouncedSearchValue("");
  };

  const handleSearchValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  return (
    <TextField
      size={size}
      value={searchValue}
      onChange={handleSearchValue}
      placeholder="Rechercher"
      InputProps={{
        sx: {
          bgcolor: bgColor,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ display: searchValue !== "" ? "flex" : "none" }}
            onClick={handleClear}
            sx={{ cursor: "pointer" }}
          >
            <ClearIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
