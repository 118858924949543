import React, { useState } from "react";
import { ProductRow } from "../../../../../../../data/Product/models";
import {
  Box,
  Button,
  Card,
  CardContent,
  InputLabel,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DisplayField from "../../../../../../common/cards/DisplayField";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import CustomButton from "../../../../../../common/buttons/CustomButton";
import CustomTextField from "../../CustomTextField";
import { IncidentProductRowNew } from "../../../../../../../data/Incident/types/standardEquipment";

const ImageDescriptionLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "0.5rem",
  height: "100%",
  gridTemplateColumns: "minmax(auto, 3fr) 5fr",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
  },
}));

type Props = {
  product: ProductRow;
  qtyInCart?: number;
  handleAddProduct: (product: IncidentProductRowNew) => void;
};

const ProductDialogCard = ({ product, qtyInCart, handleAddProduct }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [qty, setQty] = useState<number | undefined>(1);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleQtyChange = (newQty: number) => {
    setQty(newQty);
  };
  const handleAdd = () => {
    if (!qty) return;
    if (product.quantityonhand && qty > product.quantityonhand) {
      return;
    }
    handleAddProduct({
      vdl_Produit: {
        productid: product.productid,
        vdl_photo: product.vdl_photo,
        vdl_deserie: product.vdl_deserie,
        description: product.description,
        name: product.name,
      },
      vdl_quantite: qty,
    });
  };
  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent sx={{ height: "100%" }}>
        <Box display="flex" flexDirection="column" gap="24px" height="100%">
          <Typography variant="h6">{product.name}</Typography>
          <ImageDescriptionLayout>
            <Box
              maxHeight="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src={"data:image/png;base64," + product.vdl_photo}
                sx={{ objectFit: "contain" }}
                maxWidth="100%"
                height="100%"
                maxHeight="100%"
                minWidth="0"
              />
            </Box>
            {sm ? (
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={() => setOpen(!open)} color="info">
                  Détails
                  {open ? (
                    <KeyboardDoubleArrowUpIcon />
                  ) : (
                    <KeyboardDoubleArrowDownIcon />
                  )}
                </Button>
              </Box>
            ) : null}

            <Box display="flex" alignItems="center" height="100%">
              <Box
                display="flex"
                flexDirection="column"
                gap="1rem"
                justifyContent="space-between"
              >
                {!sm || open ? (
                  <>
                    <DisplayField
                      displayName={"Description"}
                      value={product.description}
                    />
                    <DisplayField
                      displayName={"Dimensions"}
                      value={product.vdl_dimension}
                    />
                    {product.vdl_remarque ? (
                      <DisplayField
                        displayName={"Remarque"}
                        value={product.vdl_remarque}
                      />
                    ) : null}
                  </>
                ) : null}
              </Box>
            </Box>
          </ImageDescriptionLayout>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: {
                xs: "8px",
                sm: "32px",
              },
              justifyContent: {
                xs: "space-between",
                sm: "flex-end",
              },
            }}
          >
            <Box display="flex" gap="8px" alignItems="center">
              {sm ? null : (
                <InputLabel disabled={qtyInCart !== undefined}>
                  Quantité:
                </InputLabel>
              )}
              <CustomTextField
                value={qty ? qty : ""}
                onQtyChange={handleQtyChange}
                label={sm ? "Quantité" : undefined}
                disabled={qtyInCart !== undefined}
                name="qte_to_add"
                inputProps={{
                  min: "1",
                  max: product.quantityonhand,
                  step: "1",
                }}
              />
            </Box>
            <CustomButton
              primary
              disabled={qtyInCart !== undefined}
              onClick={handleAdd}
            >
              {qtyInCart === undefined ? "Ajouter" : "Ajouté"}
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductDialogCard;
