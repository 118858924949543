import React, { useEffect, useMemo, useState } from "react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import BasicTabs from "../components/layouts/AppTabs/AppTabs";
import { ErrorType, QueryError, User } from "../types";
import {
  IncidentParentRow,
  IncidentReferenceRow,
} from "../data/Incident/models";
import useUser from "../hooks/useUserData";
import { Box, InputAdornment, Skeleton, TextField } from "@mui/material";
import DemandRegroupment from "../components/pageSpecific/Demands/DemandRegroupment/DemandRegroupment";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import DemandCardSkeleton from "../components/pageSpecific/Demands/DemandCard/DemandCardSkeleton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AppTabsSkeleton from "../components/layouts/AppTabs/AppTabsSkeleton";
import { deleteIncident, getIncidents } from "../data/Incident/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import DemandeCardNew from "../components/pageSpecific/Demands/DemandCard/DemandeCardNew";
import { canUserReadIncident } from "../data/Incident/rules";
import useTabs from "../hooks/useTabs";
import SearchField from "../components/pageSpecific/Demands/SearchField";
import { useSnackbar } from "../hooks/useSnackbar";

function filterDemandsByStatusCode(
  tab: number,
  demands: IncidentParentRow[] | undefined,
  user: User
): IncidentParentRow[] {
  if (demands === undefined) return [];
  const statusCodesToFilter =
    tab === 0
      ? [744610001, 2, 948300001, 948300004, 3, 948300002, 1]
      : [6, 5, 948300003];

  const filterByStatus = (demands: IncidentReferenceRow[]) => {
    return demands.filter(
      (subDemand) =>
        statusCodesToFilter.includes(subDemand.statuscode) &&
        canUserReadIncident(user, subDemand._vdl_contactprincipal_value)
    );
  };

  const tempDemands: IncidentParentRow[] = [];

  demands.forEach((demand: IncidentParentRow) => {
    if (
      statusCodesToFilter.includes(demand.statuscode) &&
      canUserReadIncident(user, demand._vdl_contactprincipal_value)
    ) {
      tempDemands.push(demand);
    } else if (
      demand.incident_parent_incident &&
      demand.incident_parent_incident.length > 0
    ) {
      const filteredDemands = filterByStatus(demand.incident_parent_incident);
      if (filteredDemands.length > 0) {
        tempDemands.push(demand);
      }
    }
  });

  return tempDemands;
}

function filterDemands(
  demands: IncidentParentRow[],
  searchValue: string
): IncidentParentRow[] {
  const searchValueLowercase = searchValue.trim().toLowerCase();

  if (searchValueLowercase === "") return demands;

  const tempDemands: IncidentParentRow[] = [];

  demands.forEach((demand: IncidentParentRow) => {
    if (
      demand.title.toLowerCase().includes(searchValueLowercase) ||
      (demand.ticketnumber &&
        demand.ticketnumber.toLowerCase().includes(searchValueLowercase))
    ) {
      tempDemands.push(demand);
    } else if (
      demand.incident_parent_incident &&
      demand.incident_parent_incident.length > 0
    ) {
      const filteredDemands = demand.incident_parent_incident.filter(
        (sub: IncidentReferenceRow) =>
          sub.title.toLowerCase().includes(searchValueLowercase) ||
          (sub.ticketnumber &&
            sub.ticketnumber.toLowerCase().includes(searchValueLowercase))
      );
      if (filteredDemands.length > 0) {
        tempDemands.push(demand);
      }
    }
  });
  return tempDemands;
}

const Demands = () => {
  const { userData } = useUser();

  const { data, error, isLoading, isFetching } = useQuery<
    IncidentParentRow[],
    QueryError
  >({
    queryKey: ["Demands"],
    queryFn: () => getIncidents(),
  });

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const openDemands = useMemo(
    () => filterDemandsByStatusCode(0, data, userData),
    [data, userData]
  );
  const closedDemands = useMemo(
    () => filterDemandsByStatusCode(1, data, userData),
    [data, userData]
  );

  if (isLoading || isFetching) return <DemandsPageLoading />;

  if (error || !data) return <QueryErrorMessage error={error} />;

  return (
    <PageTitleLayout title="Mes demandes">
      <BasicTabs
        tabs={[
          {
            label: `Demandes en cours (${openDemands.length})`,
            component: (
              <Box display="grid" gap="16px">
                <SearchField
                  setDebouncedSearchValue={setDebouncedSearchValue}
                />
                {filterDemands(openDemands, debouncedSearchValue).map(
                  (demand, idx) =>
                    demand.incident_parent_incident &&
                    demand.incident_parent_incident.length > 0 ? (
                      <DemandRegroupment key={idx} demand={demand} />
                    ) : (
                      <DemandeCardNew key={demand.incidentid} demand={demand} />
                    )
                )}
              </Box>
            ),
          },
          {
            label: `Demandes fermées (${closedDemands.length})`,
            component: (
              <Box display="grid" gap="16px">
                <SearchField
                  setDebouncedSearchValue={setDebouncedSearchValue}
                  size={"small"}
                />
                {filterDemands(closedDemands, debouncedSearchValue).map(
                  (demand, idx) =>
                    demand.incident_parent_incident &&
                    demand.incident_parent_incident.length > 0 ? (
                      <DemandRegroupment key={idx} demand={demand} />
                    ) : (
                      <DemandeCardNew key={demand.incidentid} demand={demand} />
                    )
                )}
              </Box>
            ),
          },
        ]}
      />
    </PageTitleLayout>
  );
};

export default Demands;

const DemandsPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Mes demandes">
      <AppTabsSkeleton tabsNumber={2} activeTabIndex={1} />

      <BoxResponsivePadding display="grid" gap="16px">
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: "4px / 6.7px ",
            fontSize: "2.08rem",
          }}
        />
        {Array(2)
          .fill(0)
          .map((i, idx) => (
            <DemandCardSkeleton icon key={idx} />
          ))}
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
