import React from "react";
import ClickableIcon from "../../../../../common/buttons/icons/ClickableIcon";
import { Close, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Collapse,
  InputAdornment,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchField from "../../../../Demands/SearchField";

const HeaderLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "auto auto",
  padding: "16px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    gridTemplateRows: "auto auto",
    justifyContent: "space-between",
  },
}));

type Props = {
  defaultCategory: string;
  category: string;
  categories: string[];
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProductDialogHeader = ({
  defaultCategory,
  category,
  categories,
  setCategory,
  searchText,
  setSearchText,
  setOpen,
}: Props) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCategoryChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    if (value === null) {
      setCategory(defaultCategory);
    } else {
      setCategory(value);
    }
  };

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchText(value);
  }

  const filters = (
    <>
      <Autocomplete
        value={category}
        onChange={handleCategoryChange}
        unselectable={"off"}
        openText="Ouvrir"
        clearText="Effacer"
        sx={{
          minWidth: "120px",
          width: "100%",
          maxWidth: "270px",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Catégorie"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
              },
            }}
          />
        )}
        options={[defaultCategory, ...categories]}
      />
    <SearchField setDebouncedSearchValue={setSearchText} bgColor="white"/>
    </>
  );

  return (
    <HeaderLayout bgcolor={"secondary.main"}>
      <Typography variant="h4" fontWeight="bold" color="white">
        Catalogue de matériel
      </Typography>
      <Box display="flex" gap="32px" alignItems="center">
        {sm ? null : (
          <Box
            display="flex"
            gap="16px"
            flexWrap="wrap"
            justifyContent="flex-end"
            width="100%"
          >
            {filters}
          </Box>
        )}
        <ClickableIcon
          icon={<Close />}
          handleClick={() => {
            setOpen(false);
          }}
          color="white"
          size={40}
        />
      </Box>
      {sm ? (
        <Box width="100%" gridColumn="span 2">
          <Box display="flex" flexWrap="wrap" gap="16px">
            {filters}
          </Box>
        </Box>
      ) : null}
    </HeaderLayout>
  );
};

export default ProductDialogHeader;
