import React, { useEffect, useState } from "react";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";
import EditDemandLayout from "../EditDemandLayout";
import {
  EditDemandStandardEquipment,
  editDemandStandardEquipmentFields,
  editDemandStandardEquipmentLivraisonSection,
  editDemandStandardEquipmentRecuperationSection,
} from "../../../../data/Incident/forms/standardEquipment";
import { Box, styled } from "@mui/material";
import ProductsSection from "./Products/ProductsSection";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import { buildFormObj } from "../../../form/SimpleForm/buildForm";
import {
  IncidentPatch,
  IncidentStandardEquipmentPatch,
  IncidentStandardEquipmentRow,
} from "../../../../data/Incident/models";
import { IncidentProductRowEdit } from "../../../../data/Incident/types/standardEquipment";
import { ApiKeyManager } from "@esri/arcgis-rest-request";
import { IGeocodeResponse, geocode } from "@esri/arcgis-rest-geocoding";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";
import { getAddressCandidates } from "../../../../data/Address/queries";
import { dayjsToUtcString } from "../../../../data/common/dayjsToUtcString";
import { buildAddress, parseAddress } from "../../../../data/addressHelper";

const CardsLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 1fr",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr",
  },
}));

type Props = {
  demand: IncidentStandardEquipmentRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const StandardEquipment = ({ demand, generalFields, update }: Props) => {
  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(generalFields);
  const [fields, setFields] = useState<EditDemandStandardEquipment>(() => {
    const livraisonAddress = buildAddress({
      street: demand.vdl_detailsdelademande.vdl_rue1_livraison,
      apartment: demand.vdl_detailsdelademande.vdl_rue2_livraison,
      city: demand.vdl_detailsdelademande.vdl_ville1_livraison,
      province: demand.vdl_detailsdelademande.vdl_departementprovince_livraison,
      postalCode: demand.vdl_detailsdelademande.vdl_codepostal1_livraison,
      country: demand.vdl_detailsdelademande.vdl_pays1_livraison,
    });

    const receptionAddress = demand.vdl_detailsdelademande
      .vdl_identiquealadressedelivraison
      ? livraisonAddress
      : buildAddress({
          street: demand.vdl_detailsdelademande.vdl_rue_recuperation,
          apartment: demand.vdl_detailsdelademande.vdl_rue2_recuperation,
          city: demand.vdl_detailsdelademande.vdl_ville_recuperation,
          province:
            demand.vdl_detailsdelademande.vdl_departementprovince_recuperation,
          postalCode: demand.vdl_detailsdelademande.vdl_codepostal_recuperation,
          country: demand.vdl_detailsdelademande.vdl_pays_recuperation,
        });

    return buildFormObj(editDemandStandardEquipmentFields, {
      vdl_adressecompletelivraison: {
        value: livraisonAddress
          ? {
              text: livraisonAddress,
              magicKey: "",
            }
          : null,
      },
      vdl_adressecompleterecuperation: {
        value: receptionAddress
          ? {
              text: receptionAddress,
              magicKey: "",
            }
          : null,
      },
      vdl_datedelivraison: {
        value: validateDateString(
          demand.vdl_detailsdelademande.vdl_datedelivraison
        ),
      },
      vdl_datederecuperation: {
        value: validateDateString(
          demand.vdl_detailsdelademande.vdl_datederecuperation
        ),
      },
      vdl_nomdubatimentlivraison: {
        value: demand.vdl_detailsdelademande.vdl_nomdubatimentlivraison ?? "",
      },
      vdl_nomdubatimentrecuperation: {
        value:
          demand.vdl_detailsdelademande.vdl_nomdubatimentrecuperation ?? "",
      },
      vdl_identiquealadressedelivraison: {
        value:
          demand.vdl_detailsdelademande.vdl_identiquealadressedelivraison ??
          false,
      },
      vdl_secteurdeladressedelivraison: {
        value: demand.vdl_detailsdelademande.vdl_secteurdeladressedelivraison,
      },
      vdl_secteurdeladressederecuperation: {
        value:
          demand.vdl_detailsdelademande.vdl_secteurdeladressederecuperation,
      },
      vdl_horaire: { value: demand.vdl_detailsdelademande.vdl_horaire },
      vdl_horairerecuperation: {
        value: demand.vdl_detailsdelademande.vdl_horairerecuperation,
      },
      vdl_remarqueoucommentaire: {
        value: demand.vdl_detailsdelademande.vdl_remarqueoucommentaire ?? "",
      },
      vdl_remarqueoucommentairerecuperation: {
        value:
          demand.vdl_detailsdelademande.vdl_remarqueoucommentairerecuperation ??
          "",
      },
    });
  });
  const [demandProducts, setDemandProducts] = useState<
    IncidentProductRowEdit[]
  >(demand.vdl_detailsdelademande.products);
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const sameAddress = fields.vdl_identiquealadressedelivraison.value;

    const livraisonAddress =
      fields.vdl_adressecompletelivraison.value &&
      fields.vdl_adressecompletelivraison.changed
        ? parseAddress(fields.vdl_adressecompletelivraison.value.text)
        : {
            street: demand.vdl_detailsdelademande.vdl_rue1_livraison,
            apartment: demand.vdl_detailsdelademande.vdl_rue2_livraison,
            city: demand.vdl_detailsdelademande.vdl_ville1_livraison,
            province:
              demand.vdl_detailsdelademande.vdl_departementprovince_livraison,
            postalCode: demand.vdl_detailsdelademande.vdl_codepostal1_livraison,
            country: demand.vdl_detailsdelademande.vdl_pays1_livraison,
          };

    const receptionAddress = sameAddress
      ? livraisonAddress
      : fields.vdl_adressecompleterecuperation.value &&
        fields.vdl_adressecompleterecuperation.changed
      ? parseAddress(fields.vdl_adressecompleterecuperation.value.text)
      : {
          street: demand.vdl_detailsdelademande.vdl_rue_recuperation,
          apartment: demand.vdl_detailsdelademande.vdl_rue2_recuperation,
          city: demand.vdl_detailsdelademande.vdl_ville_recuperation,
          province:
            demand.vdl_detailsdelademande.vdl_departementprovince_recuperation,
          postalCode: demand.vdl_detailsdelademande.vdl_codepostal_recuperation,
          country: demand.vdl_detailsdelademande.vdl_pays_recuperation,
        };

    const _demand: IncidentStandardEquipmentPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_identiquealadressedelivraison:
          fields.vdl_identiquealadressedelivraison.value,
        vdl_nomdubatimentrecuperation:
          fields.vdl_nomdubatimentrecuperation.value,
        vdl_nomdubatimentlivraison: fields.vdl_nomdubatimentlivraison.value,
        vdl_datedelivraison: dayjsToUtcString(fields.vdl_datedelivraison.value),
        vdl_datederecuperation: dayjsToUtcString(
          fields.vdl_datederecuperation.value
        ),
        vdl_secteurdeladressedelivraison:
          fields.vdl_secteurdeladressedelivraison.value,
        vdl_secteurdeladressederecuperation: sameAddress
          ? fields.vdl_secteurdeladressedelivraison.value
          : fields.vdl_secteurdeladressederecuperation.value,

        vdl_remarqueoucommentaire: fields.vdl_remarqueoucommentaire.value,
        vdl_remarqueoucommentairerecuperation:
          fields.vdl_remarqueoucommentairerecuperation.value,
        vdl_horaire: fields.vdl_horaire.value,
        vdl_horairerecuperation: fields.vdl_horairerecuperation.value,
        vdl_rue1_livraison: livraisonAddress?.street,
        vdl_rue2_livraison: livraisonAddress?.apartment,
        vdl_ville1_livraison: livraisonAddress?.city,
        vdl_departementprovince_livraison: livraisonAddress?.province,
        vdl_codepostal1_livraison: livraisonAddress?.postalCode,
        vdl_pays1_livraison: livraisonAddress?.country,

        vdl_rue_recuperation: receptionAddress?.street,
        vdl_rue2_recuperation: receptionAddress?.apartment,
        vdl_ville_recuperation: receptionAddress?.city,
        vdl_departementprovince_recuperation: receptionAddress?.province,
        vdl_codepostal_recuperation: receptionAddress?.postalCode,
        vdl_pays_recuperation: receptionAddress?.country,
        products: [],
      },
    };

    _demand.vdl_detailsdelademande.products = demandProducts.map((p) => {
      if ("vdl_equipementdelademandeid" in p) {
        if ("delete" in p && p.delete === true) {
          return {
            vdl_equipementdelademandeid: p.vdl_equipementdelademandeid,
            delete: true,
          };
        }
        return {
          vdl_equipementdelademandeid: p.vdl_equipementdelademandeid,
          vdl_quantite: p.vdl_quantite,
        };
      }
      return {
        vdl_quantite: p.vdl_quantite,
        _vdl_produit_value: p.vdl_Produit.productid,
      };
    });

    await update(_demand);
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}
    >
      <>
        <CardsLayout>
          <SimpleFields
            fields={fields}
            setFields={setFields}
            sections={[editDemandStandardEquipmentLivraisonSection]}
            sectionsBackground="background.paper"
            columns={{ count: 2, minWidth: 260 }}
          />
          <SimpleFields
            fields={fields}
            setFields={setFields}
            sections={[editDemandStandardEquipmentRecuperationSection]}
            sectionsBackground="background.paper"
            columns={{ count: 2, minWidth: 260 }}
          />
          <Box
            sx={{
              gridColumn: {
                lg: "span 2",
              },
            }}
            bgcolor="common.white"
            padding="16px"
          >
            <ProductsSection
              demandProducts={demandProducts}
              setDemandProducts={setDemandProducts}
              productTypes={
                demand.vdl_detailsdelademande._vdl_typedeproduit_value
              }
            />
          </Box>
        </CardsLayout>
      </>
    </EditDemandLayout>
  );
};

export default StandardEquipment;
